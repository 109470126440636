import styled from 'styled-components';
import media from 'styled-media-query';

export const Jumbotron = styled.div`
    margin-bottom: 2rem;
    padding: 1rem 0;

    ${media.greaterThan('medium')`
		display: flex;
        margin-bottom: 5rem;
        padding: 2rem 0;
	`}
`;

export const JumbotronTexts = styled.div``;

export const JumbotronTitle = styled.div`
    font-family: 'Bebas Neue';
    font-size: 6.5rem;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;

    ${media.greaterThan('medium')`
        font-size: 8.5rem;
        letter-spacing: 4px;
	`}
`;

export const JumbotronText = styled.div`
    font-size: 1.25rem;
    line-height: 1.25;
`;

export const JumbotronImages = styled.div`
    display: none;

    ${media.greaterThan('medium')`
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: 4rem;
        padding: 0 2rem;
	`}
`;

export const JumbotronImage = styled.div`
    height: 350px;
    position: relative;
    width: 350px;

    &:before,
    &:after {
        background-color: white;
        border-radius: 50%;
        content: '';
        height: 250px;
        position: absolute;
        width: 250px;
        z-index: -1;
    }

    &:before {
        top: -25px;
        left: -25px;
    }

    &:after {
        bottom: -50px;
        right: -50px;
    }
`;
