import styled from 'styled-components';

export const LastPosts = styled.div`
    padding: 2rem 0;
`;

export const LastPostsTitle = styled.div`
    font-size: 1.25rem;
    margin-bottom: 2rem;
`;

