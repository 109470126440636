import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Container } from '../styles';

import { Jumbotron, LastPosts, LastVideos } from '../components/Home';

const BlogPost = (props) => {
    const postList = props.data.allMarkdownRemark.edges;

	return (
		<Layout>
			<SEO title="Home" />
            <Container>
                <Jumbotron />

                {/* <LastVideos /> */}

                <LastPosts lastPosts={postList} />
            </Container>
		</Layout>
	)
}

export const query = graphql`
	query GetLastPosts($limit: Int!) {
		allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }, limit: $limit) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						background
						category
						description
						date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
						title
                        image
					}
					timeToRead
				}
			}
		}
	}
`;

export default BlogPost;
