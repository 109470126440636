import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'gatsby';

export const PostItem = styled.div`
    align-items: center;
    border-top: 1px solid var(--background-auxiliar);
	color: var(--background-font);
	display: flex;
    padding: 2rem 0;
	text-decoration: none;
	transition: color 0.3s;
`;

export const PostItemTitle = styled.h1`
	font-size: 1.6rem;
	font-weight: 700;
	margin: 0.2rem 0 0.5rem;
`;

export const PostItemDescription = styled.p`
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 1.2;
`;

export const PostLink = styled(Link)`
    color: var(--background-font);

    &:hover {
        ${PostItemTitle} {
            text-decoration: underline;
        }
    }
`;

export const PostItemWrapper = styled.section`
    align-items: flex-start;
	display: flex;
	width: 100%;

    ${media.lessThan('large')`
        flex-direction: column;
    `}

    ${media.greaterThan('large')`
        justify-content: space-between;
	`}
`;

export const PostItemInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PostItemDate = styled.time`
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
`;

export const PostItemCategory = styled.div`
    background-color: var(--background-auxiliar);
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;

    ${media.lessThan('large')`
        margin-top: 1rem;
    `}
`;
