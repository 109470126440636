import React from 'react';
import PropTypes from 'prop-types';

import getThemeColor from '../../utils/getThemeColor';
import * as S from './styled';

const PostItem = ({ slug, date, title, description, category }) => (
    <S.PostItem>
        <S.PostItemWrapper>
            <S.PostItemInfo>
                <S.PostItemDate>{date}</S.PostItemDate>
                <S.PostLink to={slug} cover direction="right" bg={getThemeColor()} duration={0.6}>
                    <S.PostItemTitle>{title}</S.PostItemTitle>
                    <S.PostItemDescription>{description}</S.PostItemDescription>
                </S.PostLink>
            </S.PostItemInfo>

            <S.PostItemCategory>{category}</S.PostItemCategory>
        </S.PostItemWrapper>
    </S.PostItem>
);

PostItem.propTypes = {
	slug: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
}

export default PostItem;
