import styled from 'styled-components';
import media from 'styled-media-query';

export const LastVideos = styled.div`
    padding: 2rem 0;
`;

export const LastVideosTitle = styled.div`
    font-size: 1.25rem;
    margin-bottom: 2rem;
`;

export const LastVideosList = styled.div`
    display: grid;
    font-size: 1.25rem;
    gap: 2rem;
    margin-bottom: 2rem;

    ${media.greaterThan('medium')`
        grid-template-columns: 1fr 1fr 1fr;
	`}
`;

export const VideoWrapper = styled.div`
    align-items: center;
	color: var(--background-font);
`;

export const VideoThumbnail = styled.div`
    border: 1px solid var(--background-auxiliar);
    height: 100px;
`;

export const VideoTitle = styled.div`
    color: var(--background-font);
    margin-top: 1rem;
`;
