import React from 'react';

import * as S from './styles';

const LastVideos = () => (
    <S.LastVideos>
        <S.LastVideosTitle>Últimos videos</S.LastVideosTitle>

        <S.LastVideosList>
            <S.VideoWrapper>
                <S.VideoThumbnail />
                <S.VideoTitle>Titulo do video</S.VideoTitle>
            </S.VideoWrapper>
            <S.VideoWrapper>
                <S.VideoThumbnail />
                <S.VideoTitle>Titulo do video</S.VideoTitle>
            </S.VideoWrapper>
            <S.VideoWrapper>
                <S.VideoThumbnail />
                <S.VideoTitle>Titulo do video</S.VideoTitle>
            </S.VideoWrapper>
        </S.LastVideosList>

    </S.LastVideos>
);

export default LastVideos;
