import React from 'react';
import PostItem from '../../PostItem';

import * as S from './styles';

const LastPosts = ({ lastPosts }) => (
    <S.LastPosts>
        <S.LastPostsTitle>Últimos artigos</S.LastPostsTitle>
        {lastPosts.map(({
            node: {
                fields: { slug },
                frontmatter: { background, category, date, description, title, image },
                timeToRead
            },
        }) => (
            <PostItem
                key={date}
                slug={slug}
                background={background}
                category={category}
                date={date}
                timeToRead={timeToRead}
                title={title}
                description={description}
                image={image}
            />
        ))}
    </S.LastPosts>
);

export default LastPosts;
