import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from './styles';

const Jumbotron = () => (
    <S.Jumbotron>
        <S.JumbotronTexts>
            <S.JumbotronTitle>Code<br />is Cool</S.JumbotronTitle>
            <S.JumbotronText>Algumas coisas legais de front end pra compartilhar com a galera :)</S.JumbotronText>
        </S.JumbotronTexts>

        <S.JumbotronImages>
            <S.JumbotronImage>
                <StaticImage
                    src="../../../images/jumbotron.png"
                    alt="Code is cool logo"
                    placeholder="blurred"
                />
            </S.JumbotronImage>
        </S.JumbotronImages>
    </S.Jumbotron>
);

export default Jumbotron;
